import React from 'react';
import details1 from '../../assets/images/services/services-details1.jpg';
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const jobTypes = {
    webDesigner: {
        title: "WEBデザイナー",
        "content": <React.Fragment><p>Webデザインを主にデザイン・ディレクションに関する業務全般。</p>
            <ul>
                <li>UI・UX設計</li>
                <li>ホームページのデザイン</li>
                <li>ユーザーマニュアルデザイン</li>
                <li>その他のデザインに関する業務</li>
                <li>コーディング</li>
            </ul>
        </React.Fragment>,
        "environment": <React.Fragment>
            <ul>
                <li>Photoshop／Illustrator</li>
                <li>Sketch／Figma</li>
                <li>Asana, Instagantt</li>
            </ul>
        </React.Fragment>,
        "skill": <React.Fragment>
            <strong>【必須スキル・経験】</strong>
            <ul>
                <li>Webデザインの業務経験２年以上</li>
                <li>インフォグラフィックデザインの制作経験</li>
            </ul>
            <strong>【歓迎スキル・経験】</strong>
            <ul>
                <li>UI・UXの設計経験</li>
                <li>ユーザービリティ動向設計</li>
                <li>フロントエンド開発（コーディング）</li>
                <li>フラットデザインの制作経験</li>
            </ul>
        </React.Fragment>,
        salary: <React.Fragment>
            <strong>【雇用形態】</strong><br />
            アルバイト<br />
            <strong>【給与】</strong><br />
            時給 1,200円～3,000円<br />
        </React.Fragment>
    },
    developer:  {
        title: "システムエンジニア",
        "content": <React.Fragment><p>ソフトウェアの開発・運用保守を行っていただきます。</p>
            <ul>
                <li>フロントエンド開発</li>
                <li>サーバーアプリケーション開発</li>
                <li>DB・API設計・開発</li>
            </ul>
        </React.Fragment>,
        "environment": <React.Fragment>
            <ul>
                <li>プログラミング言語： Node.js</li>
                <li>フレームワーク：React, Redux, Gatsby, Next.js, Express.js</li>
                <li>インフラ：AWS, GCP</li>
                <li>DB・データ：MySQL, Redis,</li>
            </ul>
        </React.Fragment>,
        "skill": <React.Fragment>
            <strong>【必須スキル・経験】</strong>
            <ul>
                <li>WEBサービスの開発経験2年以上</li>
                <li>HTML、CSSに関する最低限の知識</li>
                <li>フロント系JavaScriptの開発経験</li>
            </ul>
            <strong>【歓迎スキル・経験】</strong>
            <ul>
                <li>SPA（React.js、Angular.js、Vue.js、Riot.js）の開発経験</li>
                <li>Javascriptに関する深い知識</li>
                <li>サーバーレス構築・運用経験</li>
                <li>システムアーキテクチャ設計経験</li>
                <li>リレーショナル型DB・ドキュメント型DBの設計経験</li>
                <li>オープンソースへの貢献</li>
            </ul>
            <strong>【望ましい人物像】</strong>
            <ul>
                <li>エンジニアとして強い向上心と好奇心を持っている方</li>
                <li>勉強会や技術イベントなどに積極的に参加している方</li>
                <li>新しいサービス／言語／ツールが出るととりあえず試してみる方</li>
                <li>セルフマネジメントができる方</li>
                <li>チーム一丸となって新規事業を盛り上げてくれる方</li>
            </ul>
        </React.Fragment>,
        salary: <React.Fragment>
            <strong>【雇用形態】</strong>
            <ul>
                <li>正社員</li>
                <li>業務委託（フリーランス）</li>
                <li>アルバイト</li>
            </ul>
            <strong>【給与】</strong>
            <ul>
                <li>正社員：応相談</li>
                <li>アルバイト：1500円～5000円</li>
            </ul>
        </React.Fragment>
    },
    infrastructure:  {
        title: "インフラエンジニア",
        "content": <React.Fragment><p>ソフトウェアのサーバーサイド・インフラ構築開発・運用保守を行っていただきます。</p>
            <ul>
                <li>AWSを使用したインフラの設計構築・運用保守</li>
                <li>サーバーアプリケーション開発</li>
            </ul>
        </React.Fragment>,
        "environment": <React.Fragment>
            <ul>
                <li>プログラミング言語： Node.js</li>
                <li>フレームワーク：React, Redux, Express.js</li>
                <li>インフラ：AWS, GCP</li>
                <li>DB・データ：MySQL, Redis,</li>
            </ul>
        </React.Fragment>,
        "skill": <React.Fragment>
            <strong>【必須スキル・経験】</strong>
            <ul>
                <li>AWSの構築・運用保守経験2年以上</li>
                <li>Dockerの知識、コンテナ運用・構築</li>
            </ul>
            <strong>【歓迎スキル・経験】</strong>
            <ul>
                <li>WEB開発経験（言語問わず）</li>
                <li>terraformなどを使用したIaCの経験</li>
                <li>コンテナオーケストレーションの構築・運用</li>
                <li>サーバーレス構築・運用経験</li>
                <li>DevOps経験・導入支援</li>
                <li>CI環境の構築・導入支援</li>
            </ul>
            <strong>【望ましい人物像】</strong>
            <ul>
                <li>エンジニアとして強い向上心と好奇心を持っている方</li>
                <li>勉強会や技術イベントなどに積極的に参加している方</li>
                <li>新しいサービス／言語／ツールが出るととりあえず試してみる方</li>
                <li>常に個人的に何かを作り続けている方</li>
                <li>チーム一丸となって新規事業を盛り上げてくれる方</li>
            </ul>
        </React.Fragment>,
        salary: <React.Fragment>
            <strong>【雇用形態】</strong>
            <ul>
                <li>正社員</li>
                <li>業務委託（フリーランス）</li>
                <li>アルバイト</li>
            </ul>
            <strong>【給与】</strong>
            <ul>
                <li>正社員：応相談</li>
                <li>アルバイト：1500円～5000円</li>
            </ul>
        </React.Fragment>
    },
    customerSupport:  {
        title: "カスタマーサポート",
        "content": <React.Fragment><p>リタッチのサービスに対する「操作方法」、「料金プラン」、「機能要件」など、製品に関するお客様からのお問い合わせ対応（電話／メール／チャット）及び事務作業の補佐業務</p>
        </React.Fragment>,
        "skill": <React.Fragment>
            <strong>【必須スキル・経験】</strong>
            <ul>
                <li>エクセル、ワードなどPCを使った業務経験</li>
                <li>SNSの使用経験及び理解</li>
            </ul>
            <strong>【歓迎スキル・経験】</strong>
            <ul>
                <li>カスタマーサポートの経験（法人）</li>
                <li>法人営業の経験</li>
                <li>エクセル/ワード/パワーポイントなどの深い知識・経験</li>
                <li>経理・労務などの総務経験</li>
            </ul>
        </React.Fragment>,
        salary: <React.Fragment>
            <strong>【雇用形態】</strong>
            <ul>
                <li>正社員</li>
                <li>アルバイト</li>
            </ul>
            <strong>【給与】</strong>
            <ul>
                <li>正社員：応相談</li>
                <li>アルバイト：1000円～2000円</li>
            </ul>
        </React.Fragment>
    }
};
const JobsContent = (props) => {
    const job = jobTypes[props.type];
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="services-details-image">
                            <StaticImage src="../../assets/images/services/services-details1.jpg" alt="about" style={{width:'100%'}}/>
                        </div>
                        <div className="services-details-desc">
                            <h3>仕事内容</h3>
                            {job.content}
                            {job.environment ? <React.Fragment><h3>開発環境</h3>{job.environment}</React.Fragment>: ''}
                            <h3>求めるスキル</h3>
                            {job.skill}
                            <h3>給与・待遇</h3>
                            {job.salary}
                            <h3>勤務時間</h3>
                            <ul>
                                <li>9:00～18:00</li>
                                <li>完全週休2日制（土・日・祝）年末年始、慶弔休暇</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default JobsContent